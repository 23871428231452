const menuItems = {
    items: [
        {
            id: 'navigation',
            title: 'Main',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    icon: 'feather icon-home',
                    url: '/dashboard',
                },
                {
                    id: 'widgets',
                    title: 'Charter',
                    type: 'collapse',
                    icon: 'feather icon-anchor',
                    badge: {
                        title: '3',
                        type: 'badge-info'
                    },
                    children: [
                        {
                            id: 'korocharter',
                            title: 'Korocharter d.o.o.',
                            type: 'item',
                            url: '/charter/1'
                        },
                        {
                            id: 'demo-charter-1',
                            title: 'Demo Charter d.o.o.',
                            type: 'item',
                            url: '/charter/2'
                        },
                        {
                            id: 'demo-charter-2',
                            title: 'Demo Charter 2 d.o.o.',
                            type: 'item',
                            url: '/charter/3'
                        },
                    ]
                }
            ]
        },
        {
            id: 'fleet',
            title: 'Fleet',
            type: 'group',
            icon: 'icon-table',
            children: [
                {
                    id: 'yacht-models',
                    title: 'Yacht Models',
                    type: 'item',
                    icon: 'feather icon-user',
                    url: '/yacht-models'
                },
                {
                    id: 'fleets',
                    title: 'Fleets',
                    type: 'item',
                    icon: 'feather icon-user',
                    url: '/fleets'
                },
                {
                    id: 'vessels',
                    title: 'Vessels',
                    type: 'item',
                    icon: 'feather icon-user',
                    url: '/vessels'
                },
            ]
        },
        {
            id: 'app',
            title: 'App',
            type: 'group',
            icon: 'icon-app',
            children: [
                {
                    id: 'staff',
                    title: 'Staff',
                    type: 'item',
                    classes: 'nav-item',
                    url: '/staff',
                    icon: 'feather icon-user'
                },
                {
                    id: 'clients',
                    title: 'Clients',
                    type: 'item',
                    classes: 'nav-item',
                    url: '/clients',
                    icon: 'feather icon-users'
                },
                {
                    id: 'reservations',
                    title: 'Reservations',
                    type: 'item',
                    classes: 'nav-item',
                    url: '/reservations',
                    icon: 'feather icon-file-text',
                    badge: {
                        title: '13',
                        type: 'badge-info'
                    },
                },
                {
                    id: 'messages',
                    title: 'Messages',
                    type: 'item',
                    classes: 'nav-item',
                    url: '/messages',
                    icon: 'feather icon-message-circle',
                    badge: {
                        title: '4',
                        type: 'badge-danger'
                    },
                },
                {
                    id: 'check-ins',
                    title: 'Check-in',
                    type: 'item',
                    icon: 'feather icon-check-square',
                    url: '/check-in',
                },
                {
                    id: 'check-outs',
                    title: 'Check-out',
                    type: 'item',
                    icon: 'feather icon-check-square',
                    url: '/check-out',
                },
            ]
        },
        {
            id: 'support',
            title: 'Support',
            type: 'group',
            icon: 'icon-support',
            children: [
                // {
                //     id: 'landing-page',
                //     title: 'App Landing Page',
                //     type: 'item',
                //     url: '/landing-page',
                //     classes: 'nav-item',
                //     icon: 'feather icon-sidebar'
                // },
                {
                    id: 'help',
                    title: 'Help & Documentation',
                    type: 'item',
                    url: '/help',
                    classes: 'nav-item',
                    icon: 'feather icon-help-circle',
                    target: true, // Opens new tab
                },
                {
                    id: 'logout-menu',
                    title: 'Logout',
                    type: 'item',
                    url: '/auth/logout',
                    classes: 'nav-item',
                    icon: 'feather icon-power'
                },
            ]
        }
    ]
};

export default menuItems;
