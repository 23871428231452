import _ from 'lodash';

import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';

import { ACCOUNT_INITIALISE, LOGIN, LOGOUT } from "../store/actions";
import axios from 'axios';
import accountReducer from '../store/accountReducer';
import Loader from "../components/Loader/Loader";

import { gql } from '@apollo/client';
import client from './../graphql/client';

const initialState = {
  isLoggedIn: false,
  isInitialised: false,
  user: null
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }

  const decoded = jwtDecode(serviceToken);
  return _.get(decoded, 'exp', Infinity) > (Date.now() / 1000);
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME, serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME);
    delete axios.defaults.headers.common.Authorization;
  }
};

const JWTContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => { }
});

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  const login = async (email, password) => {

    // Use appolo client
    const response = await client
      .mutate({
        mutation: gql`mutation Login ($username: String!, $password: String!) {
            login(args: {
                username: $username,
                password: $password
            }){
                accessToken
                headerAccessToken
            }
        }`,
        variables: { 
          username: email,
          password,
        },
        errorPolicy: 'all', // IMPORTANT if you want to handle errors like you should... :)
      })
        .then(data => {
            console.log(data)
            return data;
        })
        .catch(error => {
            console.error(error);
            return undefined;
        });


    // const response = await axios.post('/api/account/login', { email, password })
    //   .catch((error) => {
    //     console.log(error);
    //     return undefined;
    //   });

    const errors = _.get(response, 'errors', []);
    if (errors.length > 0){
      throw new Error(_.reduce(errors, (acc, item) => {
        acc.push(_.get(item, 'message', 'NO_ERROR_MESSAGE'));
        return acc;
      }, []).join(', '));
    }

    if (!response) {
      throw new Error('Invalid username or password!');
    }

    const { accessToken /* , user */ } = _.get(response, 'data.login', {});
    const user = {};
    setSession(accessToken);
    dispatch({
      type: LOGIN,
      payload: {
        user
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME);
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          // const response = await axios.get('/api/account/me');

          const response = await client
            .query({
              query: gql`query Me {
                users {
                  id
                  email
                  phone
                  username
                  user_charters {
                    charter {
                      id
                      name
                    }
                    charter_role
                  }
                  verified_email
                  verified_phone
                }
              }`,
              fetchPolicy: "no-cache", // IMPORTANT!!!
              errorPolicy: 'all', // IMPORTANT if you want to handle errors like you should... :)
            })
              .then(data => {
                console.log(data)
                return data;
              })
              .catch(error => {
                console.error(error);
                return undefined;
              });
          
          const errors = _.get(response, 'errors', []);
          if (errors.length > 0){
            throw new Error(_.reduce(errors, (acc, item) => {
              acc.push(_.get(item, 'message', 'NO_ERROR_MESSAGE'));
              return acc;
            }, []).join(', '));
          }
          if (!response) {
            throw new Error('Invalid user data!');
          }

          const user = _.get(response, 'data.users[0]', {});
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: true,
              user,
            }
          });
        } else {
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: ACCOUNT_INITIALISE,
          payload: {
            isLoggedIn: false,
            user: null
          }
        });
      }
    };

    init();
  }, []);

  if (!state.isInitialised) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider value={{ ...state, login, logout}}>
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
