const friends = [
    {
        id: 1,
        photo: 'avatar-1.jpg',
        name: 'Josephin Doe',
        new: 3,
        status: 1,
        time: 'typing',
    },
    {
        id: 2,
        photo: 'avatar-2.jpg',
        name: 'Lary Doe',
        new: 1,
        status: 1,
        time: 'online',
    },
];

export default friends;
