import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import { ApolloProvider } from '@apollo/client/react';

import './services';

import { Provider } from 'react-redux';
import { ConfigProvider } from "./contexts/ConfigContext";

import './index.scss';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import store from "./store";

import client from './graphql/client';

ReactDOM.render(
    <Provider store={store}>
        <ApolloProvider client={client}>
            <ConfigProvider>
                <App />
            </ConfigProvider>
        </ApolloProvider>
    </Provider>,
  document.getElementById('root')
);

// reportWebVitals();
